<template>
	<div>
		<PageHeader :title="$route.meta.title">
			<a-button type="primary" @click="showAddGroup">
				<a-icon type="plus" />
				Adicionar grupo
			</a-button>
		</PageHeader>
		<a-space direction="vertical" :size="16" style="width: 100%;">
			<a-alert
				message="Para que servem os grupos"
				description="Os grupos têm a funcionalidade de relacionar produtos e,
				consequentemente, itens do cardápio a um setor operacional do
				restaurante (por exemplo, produtos servidos pela Cozinha ou pelo
				Bar). Ao desativar um grupo, você estará informando que o setor
				que este grupo representa não estará atendendo a novos pedidos a
				partir deste momento."
				type="info"
				show-icon
			/>
			<a-alert
				message="Lembre-se"
				description="Ao desativar, o grupo permanecerá fechado indefinidamente, até que você volte a ativá-lo manualmente."
				type="warning"
				show-icon
			/>
			<a-row>
				<a-card
					title="Grupos adicionados"
					size="small"
					class="card"
					:body-style="{ padding: '0 12px' }"
				>
					<div slot="extra" class="extra">
						<a-button
							type="link"
							class="btn-extra"
							:disabled="!showActiveAllGroups"
							@click="handleActiveAllGroups"
						>
							Ativar todos
						</a-button>
					</div>
					<a-list
						v-if="loading"
						item-layout="horizontal"
						:data-source="
							Array.from({ length: 6 }, (_, i) => i + 1)
						"
					>
						<a-list-item
							slot="renderItem"
							key="item.title"
							slot-scope="item"
						>
							<a-skeleton
								:loading="true"
								:title="false"
								:paragraph="{ rows: 1, width: ['100%'] }"
								active
							>
								{{ item }}
							</a-skeleton>
						</a-list-item>
					</a-list>
					<a-list
						v-else
						item-layout="horizontal"
						:data-source="groups"
						:locale="ptBR"
						style="width: 100%;"
					>
						<a-list-item
							slot="renderItem"
							slot-scope="item, index"
							style="padding: 8px 0; max-width: 100%;"
						>
							<ActionsButtons
								slot="actions"
								:is-available="item.is_serving"
								:duplicate="false"
								description="grupo"
								style="
									color: rgba(0, 0, 0, 0.7);
									margin-left: 0;
								"
								@switch="(value) => handleSwitch(index, value)"
								@edit="handleEdit(item)"
								@delete="handleDelete(item.id)"
							/>
							<a-list-item-meta v-if="item">
								<div slot="title" class="name">
									<span>
										{{ item.name }}
									</span>
								</div>
							</a-list-item-meta>
						</a-list-item>
					</a-list>
				</a-card>
			</a-row>
			<GroupDrawer
				ref="groupDrawer"
				:title="groupDrawerTitle"
				:products-availables="productsAvailables"
				@update="getData"
			/>
		</a-space>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { GroupDrawer } from './components'
import { PageHeader, ActionsButtons } from '@/modules/partner/components'
import mixin from '@/utils/mixinPages'

export default {
	name: 'ProductsPage',
	components: {
		PageHeader,
		ActionsButtons,
		GroupDrawer,
	},
	mixins: [mixin],
	data() {
		return {
			loading: false,
			groups: [],
			productsAvailables: [],
			groupDrawerTitle: '',
			ptBR: { emptyText: 'Nenhum grupo adicionado até o momento' },
		}
	},
	computed: {
		...mapGetters({
			selectedMerchantId: 'merchants/selectedMerchantId',
		}),
		showActiveAllGroups() {
			return this.groups.filter((group) => !group.is_serving).length > 0
		},
	},
	watch: {
		$route: {
			immediate: true,
			handler: 'getData',
		},
	},
	methods: {
		...mapActions('merchants', [
			'getProductsGroupsList',
			'getProductGroup',
			'addProductGroup',
			'editProductGroup',
			'deleteProductGroup',
			'activeAllProductsGroups',
		]),
		...mapActions('alert', ['error']),
		async getData() {
			try {
				this.loading = true

				const {
					available_products,
					productgroups,
				} = await this.getProductsGroupsList(this.selectedMerchantId)

				this.groups = productgroups
				this.productsAvailables = available_products
			} catch (error) {
				this.error(error)
			} finally {
				this.loading = false
			}
		},
		showAddGroup() {
			this.groupDrawerTitle = 'Adicionar grupo'
			this.$refs.groupDrawer.setShowDrawer()
		},
		handleEdit(item) {
			this.groupDrawerTitle = 'Editar grupo'
			this.$refs.groupDrawer.setShowDrawer(item)
		},
		async handleSwitch(index, value) {
			try {
				const payload = {
					merchantId: this.selectedMerchantId,
					groupId: this.groups[index].id,
					data: { is_serving: value },
				}

				this.groups[index].is_serving = value
				await this.editProductGroup(payload)
			} catch (error) {
				this.error(error)
				this.groups[index].is_serving = !value
			}
		},
		async handleDelete(groupId) {
			try {
				this.loading = true

				await this.deleteProductGroup({
					merchantId: this.selectedMerchantId,
					groupId,
				})

				await this.getData()
			} catch (error) {
				this.error(error)
			} finally {
				this.loading = false
			}
		},
		async handleActiveAllGroups() {
			try {
				await this.activeAllProductsGroups(this.selectedMerchantId)

				this.groups.forEach((group) => (group.is_serving = true))
			} catch (error) {
				this.error(error)
			}
		},
	},
}
</script>

<style lang="less" scoped>
@import url('@/config/globalStyles.less');

.card {
	border-radius: 5px;
	margin-bottom: 24px;

	.extra {
		.btn-extra {
			height: 20px;
			font-weight: 600;
		}
	}
}

.name {
	font-size: 14px;
	font-weight: 600;
	color: rgba(0, 0, 0, 0.85);

	overflow-x: hidden;
	max-width: 80%;
	text-overflow: ellipsis;
	white-space: nowrap;
}
</style>
